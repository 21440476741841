if (typeof Object.disorder != 'function') {
  Object.disorder = function() {
    let b:any, c:any, d:any,a:any = this;
    c = a.length;
    while (c) b = Math.random() * (--c + 1) | 0, d = a[c], a[c] = a[b], a[b] = d
    return this;
  };
}
if (!Array.prototype["disorder"]) {
    Array.prototype["disorder"] = function () {
        let b:any, c:any, d:any,a:any[] = this;
        c = a.length;
        while (c) b = Math.random() * (--c + 1) | 0, d = a[c], a[c] = a[b], a[b] = d
        return this;
    }
}
