if (!String.prototype.repeater) {
    // don't turn this in to padding.  There is another functionfor that.
    String.prototype.repeater = function (instances:number){
        return this.repeater(instances,true);
    };
    String.prototype.repeater = function (instances:number,instances_as_max:boolean=true){
        var text = Array(instances).fill(this).join('');
        if(instances_as_max && text.length >=instances){
            text.substring(0,instances);//cutfrom left because we didn't
                                        //uncompase text.  Later we need to sort that out.
        }
        return text;
    };
}
