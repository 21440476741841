document.getElementsByTagName("html")[0].setAttribute('data-useragent', navigator.userAgent);
// to hell with things, let the dev decide if they want to set browser specific what have you.
// aim is to assume anyone masking their agent know what they are
// getting in for and is still accountable for too.


// Copied with permission from flexed
(<any>window).WSU = (<any>window).WSU || {};

(function (w: WSUstatic) {
    /**
     * Check if the first argument is not undefined first to speed check
     * then set to check if type defined as the second argument repersents.
     * a multi tool to strong type things by checking type most common
     * use is `w.defined(obj)` but `w.defined(obj,jQuery)` is super handy too
     *
     * @param {*} ns
     * @param {string} [type] named type to resolve to
     * @returns
     */
    w.defined = function (ns: any, type?: any) {
        if ('undefined' === typeof ns) {
            return false;
        }

        // if(typeof w.state !== "undefined" && typeof w.state.debug !== "undefined" && typeof w.state.debug){
        //     ((<any>window).console.log("----------------------------------w.defined") || (w.defined(w.console,"object") && w.console.log("----------------------------------w.defined")));
        //     ((<any>window).console.log(arguments.callee.name) || (w.defined(w.console,"object") && w.console.log(arguments.callee.name)));
        //     ((<any>window).console.log(this.constructor.name) || (w.defined(w.console,"object") && w.console.log(this.constructor.name)));
        //     ((<any>window).console.log(ns) || (w.defined(w.console,"object") && w.console.log(ns)));
        //     ((<any>window).console.log(typeof ns) || (w.defined(w.console,"object") && w.console.log(typeof ns)));
        //     ((<any>window).console.log(Object.prototype.toString.call(ns)) || (w.defined(w.console,"object") && w.console.log(Object.prototype.toString.call(ns))));
        //     ((<any>window).console.log(type) || (w.defined(w.console,"object") && w.console.log(type)));
        //     ((<any>window).console.log(typeof type) || (w.defined(w.console,"object") && w.console.log(typeof type)));
        //     ((<any>window).console.log(Object.prototype.toString.call(type)) || (w.defined(w.console,"object") && w.console.log(Object.prototype.toString.call(type))));
        // }
        if ('undefined' !== typeof type) {
            if ('array' === type) {
                return typeof ns === 'object' &&
                    Object.prototype.toString.call(ns) === '[object Array]';
            }
            if ('string' === type) {
                return Object.prototype.toString.call(ns) === '[object String]';
            }
            if ('number' === type) {
                return !w.defined(ns, 'array') && (ns - parseFloat(ns) + 1) >= 0;
            }
            if ('object' === type) {
                return Object.prototype.toString.call(ns) === '[object Object]';
            }
            if ('HTMLDivElement' === type) {
                return Object.prototype.toString.call(ns) === '[object HTMLDivElement]';
            }
            if ('null' === type) {
                return Object.prototype.toString.call(ns) === '[object Null]';
            }
            if ('undefined' !== typeof ns && typeof ns !== type && typeof ns === 'object' && typeof type === 'object') {

                w._l("doing instanceof of");
                return ns instanceof type;
            }
            return 'undefined' !== typeof ns && typeof ns === type;
        }
        return true;
    };

    /**
     * Still up for consideration.  not in current form but as shortcut?
     *
     * @param {*} ns
     * @param {*} [val]
     * @param {*} [ns_root]
     * @returns
     */
    w.define = function (ns: any, val?: any, ns_root?: any) {
        let parent = ns;
        if (!w.defined(ns, 'object')) {
            parent = w.prime(ns, ns_root);
        }
        try { // testing casue if it does fail well it did and that could be useful
            parent = val; // worried this will not update parent ns 100
            return true;
        } catch (e) {
            w._d(e);
            return false;
        }
    };

    /**
     * Set up containing object and merge all items from merge list.
     *
     * @param {*} obj
     * @returns concatenated object;
     */
    w.merge = function (obj: any) {
        let out: any = {};
        if (!w.defined(obj, 'object')) {
            return out;
        }
        for (let i = 0; i < obj.length; i++) {
            for (let p of Object.keys(obj[i])) { // let p in obj[i]) {
                out[p] = obj[i][p];
            }
        }
        return out;
    };

    /**
     * almost the same as w.prime but just mashes the collision of ns where
     * w.prime is is the decider
     *
     * @param {...any[]} p
     * @returns
     */
    w.extend = function (...p: any[]) {
        let options, name, src, copy, clone,
            target = arguments[0] || {},
            i = 1,
            length = arguments.length,
            deep = false;

        // Handle a deep copy situation
        if (typeof target === 'boolean') {
            deep = target;
            // Skip the boolean and the target
            target = arguments[i] || {};
            i++;
        }

        // Handle case when target is a string or something (possible in deep copy)
        if (!w.defined(target, 'object') && !w.defined(target, 'function')) {
            target = {};
        }

        // Extend flexed itself if only one argument is passed
        if (i === length) {
            target = this;
            i--;
        }

        for (; i < length; i++) {
            // Only deal with non-null/undefined values
            if ((options = arguments[i]) != null) {
                // Extend the base object
                for (name in options) {
                    if (!options.hasOwnProperty(name)) {
                        continue;
                    }
                    src = target[name];
                    copy = options[name];

                    // Prevent never-ending loop
                    if (target === copy) {
                        continue;
                    }

                    // Recurse if we're merging plain objects or arrays
                    if (deep && copy && w.defined(copy, 'object')) {
                        if (w.defined(copy, 'array')) {
                            clone = src && w.defined(src, 'array') ? src : [];
                        } else {
                            clone = src && w.defined(src, 'object') ? src : {};
                        }
                        // Never move original objects, clone them
                        target[name] = w.extend(deep, clone, copy);
                        // Don't bring in undefined values
                    } else if (copy !== undefined) {
                        target[name] = copy;
                    }
                }
            }
        }
        // Return the modified object
        return target;
    };


    /**
     * Break up input into an array in order of defined namespace
     * recurse when not a string.
     *
     * @param {*} _ns
     * @returns {*} if a string input split to array on dot
     * @final {array[string]}
     */
    w.parse_ns = function (_ns: any): any { // should be able to refactor this bit
        let out: any = [];
        if (!w.defined(_ns)) {
            return out;
        }
        if (!w.defined(_ns, 'object')) { // eg string or number //was 'object' !== typeof _ns
            try {
                out = _ns.toString().split('.');
            } catch (e) { // so at this point it was not an object, number or string... what is it?
                w._i(e, _ns);
                return false;
            }
        } else {
            _ns.forEach(function (obj: any) {
                out = out.concat(w.parse_ns(obj));
            });
        }
        return out;
    };

    /**
    * Take namespace object, parse it, check for root object passed as
    * second argument, then start there.  Apply options of setting a value
    * if set as override or merge if ns collision occurs
    *
    * @param {*} ns
    * @param {*} [ns_root]
    * @param {*} [options]
    * @returns
    */
    w.get_ns = function (ns: any) {
        let parent = window, // alais to work with
            pl: number,
            i: number,
            out: any = null;

        let parts: any = w.parse_ns(ns);
        pl = parts.length;
        for (i = 0; i < pl; i++) {
            // create a property if it doesnt exist
            if (w.defined(parent[parts[i]]) || ((i === pl - 1))) {
                let value = parent[parts[i]] || {};
                out = value;
            }
            parent = parent[parts[i]];
        }
        return out;
    };

    /**
     * Still up for consideration.
     *
     * @param {string} ns_path load ns by file
     * @param {*} [callback] do something after
     */
    w.included = []; // tracker
    w.include = function (ns_path: string, callback?: any) {
        if (w.defined(ns_path, "string") && !w.defined(w.included[ns_path])) { // if it is loaded don't worry
            let srpt = document.createElement('script');
            if (w.defined(callback, 'function')) {
                srpt.addEventListener('load', callback); // pass my hoisted function
            }
            srpt.src = ns_path + '.js';
            w.included.push(ns_path);
            document.querySelector('head').appendChild(srpt);
        }
    };

    /**
     * Set debug to console.  think field detector when leaving in production.
     *
     * @param {*} output
     * @returns {boolean} state of completion
     */
    w._d = function (output: any) {
        // return for consistency
        return w.defined(w.state.debug)
        && w.state.debug
        && ((<any>window).console.debug(output) || (w.defined(w.console,"object") && w.console.debug(output))); // tslint:disable-line
    };
    /**
         * Set debug to console.  think field detector when leaving in production.
         *
         * @param {*} output
         * @returns {boolean} state of completion
         */
    w._l = function (output: any) {
        // return for consistency
        w.defined(w.state.debug)
        && w.state.debug
        && ((<any>window).console.log(output) || (w.defined(w.console,"object") && w.console.log(output))); // tslint:disable-line
    };
    /**
     * Set log to console.  think field detector when leaving in production.
     *
     * @param {*} a
     * @param {*} b
     * @returns  {boolean} state of completion
     */
    w._i = function (a: any, b: any) {
        // return for consistency
        w.defined(w.state.debug)
        && w.state.debug
        && ((<any>window).console.info(a, b) || (w.defined(w.console,"object") && w.console.info(a, b))); // tslint:disable-line
    };


    /**
     * Take namespace object, parse it, check for root object passed as
     * second argument, then start there.  Apply options of setting a value
     * if set as override or merge if ns collision occurs
     *
     * @param {*} ns
     * @param {*} [ns_root]
     * @param {*} [options]
     * @returns
     */
    w.prime = function (ns: any, ns_root?: any, options?: any) {
        ns_root = ns_root || window; // set up the root object
        // make is do one can just put in a value and take the defaults
        options = options || { value: new Object };
        options = !w.defined(options.value) || !w.defined(options, 'object') ? { value: options } : options;
        // hulk smash?
        options = Object.assign({ override: false, merge: true, deep: true }, options); // speed it in we trust it

        let parent = ns_root, // alias to work with
            pl: number,
            i: number;

        let parts: any = w.parse_ns(ns);
        pl = parts.length;
        for (i = 0; i < pl; i++) {
            // create a property if it doesn't exist
            if (!w.defined(parent[parts[i]]) || ((i === pl - 1))) {
                let value = w.defined(parent[parts[i]]) ? parent[parts[i]] : {};
                if ((i === pl - 1) && w.defined(options.value)) {
                    if (w.defined(value) && w.defined(options.merge) && true === options.merge) {
                        if (w.defined(options.value, 'string') || w.defined(options.value, 'number')) {
                            // really?  lol why would you?  but regardless don't build walls so leave
                            value = value + options.value;
                        } else if (w.defined(options.value, 'boolean')) {
                            value = options.value === true;
                        } else {
                            value = w.extend(options.deep, value, options.value); // Object.assign(value, options.value);
                        }
                    } else if (true === options.override) {
                        value = options.value;
                    }
                }
                parent[parts[i]] = value;
            }
            parent = parent[parts[i]];
        }
        return parent;
    };

    w.render = function (html: any, options: any) {
        // @todo add better error handling for emptys at the least
        var re, add: any, match, cursor, code: any, reExp: any, result;

        re = /<%(.+?)%>/g;
        reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g;
        code = "var r=[];\n";
        cursor = 0;

        add = function (line: any, js: any) {
            if (js) {
                code += line.match(reExp) ? line + "\n" : "r.push(" + line + ");\n";
            } else {
                code += line !== "" ? "r.push('" + line.replace(/'/g, "\"") + "');\n" : "";
            }
            return add;
        };

        while ((match = re.exec(html))) {
            add(html.slice(cursor, match.index))(match[1], true);
            cursor = match.index + match[0].length;
        }
        if (WSU.defined(html, 'string')) {
            add(html.substr(cursor, html.length - cursor));
            code = (code + "return r.join('');").replace(/[\r\t\n]/g, "");
            result = new Function(code).apply(options);
        }
        return result;
    };

/** TODO
 * for things late, look at the by reference style too/ also a auto infinent arg handlr
    function foo(model: {property1: number; property2: number}) {
        model.property1++;
        model.property2++;

        // Not needed but
        // considered good practice.
        //return model;
    }
*/





    let cycle = 0;
    let frame_index = 1;
    let frame_rate =  4;
    let cycle_stop = false;
    var console_width = 80;
    var $tamper: any = null;
    var con: any = null;
    var funny: any = null;
    var rig_spd:number = 0;

    function testKey(e:any){
        if (e.ctrlKey && e.altKey && e.shiftKey && e.which == 69) {

            if(static_stamp){
                static_stamp = !static_stamp;
                stop_cycle();
            }else{
                static_stamp = !static_stamp;
                start_cycle();
            }

            w._l( "YOUR SERVICE IS `" + ( static_stamp ? "STOPPED" : "Started" ) + "`:  Ctrl + Alt + Shift + E which stops the console protection if your user is cleared to do that.  If your seeing this, you appear to have that right.  Use key strokes again to toggele the service state." );
        }
    }


    /*
     * strongly we should own the keybord where possible
     * pipe a service here.
     */
    document.onkeyup = function (e) {
        testKey(e);
        //return document.onkeyup;
    };
    var static_stamp = true;

    let funnies = [
         { u: 'A lot about living;  ', l: 'A little about love...' }
        , { u: 'Going for distance...', l: 'going for speed!!     ' }
        , { u: 'I\'ve got friends    ', l: 'in code places...     ' }
        , { u: 'Mama...              ', l: 'I just killed a bug   ' }
    ];
    function getFunny():any {
        if( funny == null || ( cycle % 3 > 0 ) ){
            var ran = Math.floor(Math.random() * funnies.length);
            function rando(ran:number=-1){
                if(ran!=last_funny_idx){
                    ran = Math.floor(Math.random() * funnies.length);
                }
                return ran;
            }
            funny = funnies[rando()];
        }
        //
        return locFun = (locFun == null ? funny : locFun);
    }

    let locFun = getFunny();

    function truck(frame: number) {
        var on_out = frame % 2 === 0;
        var rig:any = {};
        rig['kick'] = ['.', ',', '\'', ';', ' ', '\\', '„', '¸', '·', ' ', '~'];
        rig['vt'] = [' ', '_', '_', '_', '_', '|', '#', '\\', '_', ' ', ' ', ' '];

        rig['vm'] = ['[', 'W', 'S', 'U', '_', '|', '_', '|', '-', ' ', ']', ' '];
        rig['vb'] = [rig.kick.disorder()[0], '(', (['x', '+'][+ (!on_out)]), ')', ' ', ' ', ' ', '(', (['+', 'x'][+ (!on_out)]), ')', ' ', ' '];

        return rig;
    }

    function liner(char: string, length: number, alt_char: string = null, frame_index: number) {
        var live_char = (
            alt_char === null
                ? char
                : (frame_index % 2 > 0
                    ? alt_char
                    : char
                )
        );

        return Array(length).fill(char).join('').substring(0, (frame_index >= length ? length : frame_index) - 1)
            + (frame_index >= length ? char : live_char);
    }
    var lasting_funny: any = null;

    var last_funny_idx:number = 0;
    function underline(idx: number, line_set: any) {

        if(static_stamp){
            var line_length = ((line_set.l.length > line_set.u.length)
            ? line_set.l.length
            : line_set.u.length) + 4;//pad 2
            var u_pad_int = Math.floor((line_length - locFun.u.length)*.5);
            var l_pad_int = Math.floor((line_length - locFun.l.length)*.5);



            lasting_funny = {
                upperline: liner('_', line_length, 'ˍ', line_length)
                , headline:'|' + (' ').repeater(u_pad_int) + locFun.u + (' ').repeater(u_pad_int) + '|'
                , tagline:'|' + (' ').repeater(l_pad_int) + locFun.l + (' ').repeater(l_pad_int) + '|'
                , lowerline: liner('¯', line_length, '¨', line_length)
            };
            return lasting_funny;
        }



        idx= Math.floor(idx*.85);
        if (cycle === 1 || cycle % 3==0) {
            var line_length = (line_set.l.length > line_set.u.length)
            ? line_set.l.length
            : line_set.u.length;

            var bad_pad = Array(line_length).fill(' ').join('').substring(0, (idx >= line_length ? idx : line_length));


            var headline = '| ' + (locFun.u + Array(line_length*2).fill(' ').join(''))
                                        .substring(0, (idx >= line_length
                                                                    ? line_length
                                                                    : idx
                                                        ) - 1) + ' |' || "";
            var tagline = '| ' + (locFun.l + Array(line_length*2).fill(' ').join(''))
                                        .substring(0, (idx >= line_length
                                                                    ? line_length
                                                                    : idx
                                                        ) - 1) + ' |' || "";

            //['_','¯']

            lasting_funny = {
                upperline: liner('_', headline.length, 'ˍ', idx) || ""
                , headline:headline
                , tagline:tagline
                , lowerline: liner('¯', tagline.length, '¨', idx) || ""
            };
        }
        return lasting_funny;
    }
    function devert_console() {

    }
    function retrive_console() {
        con = con == null ? (
            (
                w.defined(w.console, "object")
                && w.defined(w.console.log, "function")
            ) ? (w.console)
                : (console)
        ) : con;
        return con;
    }
    function request_new_punny() {
        return "";
    }
    function stop_cycle() {
        cycle_stop = true;
    }

    function start_cycle() {
        cycle_stop = false;
        stamp(frame_index,frame_rate);
    }

    function stamp(f_idx: number = 1, f_rate: number = 1) {
        clearTimeout($tamper);
        $tamper = null;

        if(f_idx==1){
            cycle = cycle + 1;
        }

        f_idx = f_idx+1;

        if (WSU.defined(console.clear, "function")) {
            console.clear();
        }

        var speed_limt = 100;//ms shouldn't be gone past.. you can go to fast, and if you make a misstake in your extension it will crash the machine.
        var adjd_frame_rate = f_rate < speed_limt;// ('backwards in parallel to a car');
        var spead = f_idx + 1 + f_rate;

        getFunny();


        var rig = truck(f_idx);
        var spread = Math.floor(Math.random() * rig.kick.length);

        var d3 = [].concat(rig.kick.disorder());
        d3.slice(-2);
        var dirt3: any = "   " + (
                        d3.concat([' ',' '])
                        .disorder().slice(-(Math.floor( Math.random() * 5 )))
                        .join('')
        ) + "    ".substring(spread, spread + 5) + "    ";


        var d2 = [].concat(rig.kick.disorder());
        d2.slice(-2);
        var dirt2: any = " "  + (
                        d2.concat([' '])
                        .disorder().slice(-(Math.floor( Math.random() * 5 )))
                        .join('')
        ) + "  ".substring(spread, spread + 3) + "  ";

        var dirt: any = (rig.kick.disorder().slice(-(Math.floor( Math.random() * 5 ))).join('')) + " ".substring(spread, spread + 2) // + '\n-';//


        //<<-- this is the status of the mainline
        con = retrive_console();

        var m = 'c:' + (+ !((w.state.console || w.console).length > 0)
            ? "true"
            : "false") +
            ':d:' + w.state.debug.toString() +
            ':e:' + w.state.env.toString().toLowerCase();
        con.log(m);
        //________________________________________________
        //|   window * rate    |RIG|    window * rate    |
        //------------------------------------------------
        var thePAD = (' ').repeater(console_width);
        function rig_line_build(rig_space: string, f_index: number) {
            rig_spd = Math.floor(f_index + (10*(f_rate / f_idx)));
            (f_rate / f_index)
//console_width
// how fast is normal
// ratio to rig rate
// window * ratio rig ()


            var local_f = f_index*2;



            var rig_frame_space = (' ').repeater( console_width );
            var ending_position = (' ').repeater(console_width) + rig_space;
            var line: any = (
                        (
                            local_f > console_width
                            ? (' ').repeater(rig_space.length + 10)+ ending_position
                            : ending_position
                        )
                        +
                        (
                            (' ').repeater(console_width)
                            .substring(f_idx, console_width)
                        )
                    );
            line = line.substring(line.length - console_width, line.length);
            return '' + (f_index < (console_width + rig_space.length + 10) ? (thePAD + line).substring((thePAD + line).length - console_width, (thePAD + line).length) : (' ').repeater(console_width));
        }
        //
        //
        //
        var processing_at = Number.parseFloat( (f_rate / f_idx).toString() ).toFixed(2);
        var voice = underline(f_idx, locFun);// ?? new ;
        con.log('%c'
            + ('      ___           ___           ___' + (' ').repeater(72)).substring(0, console_width) + "|\n"//\n''
            + ('     /\\  \\         /\\__\\         /\\  \\' + (' ').repeater(console_width)).substring(0, console_width) + "|\n"//\n'
            + ('    _\\:\\  \\       /:/ _/_        \\:\\  \\' + (' ').repeater(8) + voice.upperline + (' ').repeater(console_width)).substring(0, console_width) + "|\n"//\n'
            + ('   /\\ \\:\\  \\     /:/ /\\  \\        \\:\\  \\' + (' ').repeater(7) + voice.headline + (' ').repeater(console_width)).substring(0, console_width) + "|\n"//\n'
            + ('  _\\:\\ \\:\\  \\   /:/ /::\\  \\   ___  \\:\\  \\' + (' ').repeater(6) + voice.tagline + (' ').repeater(console_width)).substring(0, console_width) + "|\n"//\n'
            + (' /\\ \\:\\ \\:\\__\\ /:/_/:/\\:\\__\\ /\\  \\  \\:\\__\\' + (' ').repeater(5) + voice.lowerline + (' ').repeater(console_width)).substring(0, console_width) + "|\n"//\n'
            + (' \\:\\ \\:\\/:/  / \\:\\/:/ /:/  / \\:\\  \\ /:/  /    _________    _________' + (' ').repeater(console_width)).substring(0, console_width) + "|\n"//\n'
            + ('  \\:\\ \\::/  /   \\::/ /:/  /   \\:\\  /:/  /    / ____/   \|  /  _/ ___/' + (' ').repeater(console_width)).substring(0, console_width) + "|\n"//\n'
            + ('   \\:\\/:/  /     \\/_/:/  /     \\:\\/:/  /    / /_  / /| |  / / \\__ \\' + (' ').repeater(console_width)).substring(0, console_width) + "|\n"//\n'
            + ('    \\::/  /        /:/  /       \\::/  /    / __/ / ___ |_/ / ___/ /' + (' ').repeater(console_width)).substring(0, console_width) + "|\n"//\n
            + ('     \\/__/         \\/__/         \\/__/    /_/   /_/  |_/___//____/' + (' ').repeater(console_width)).substring(0, console_width) + "|\n"//\n
            + '' + (' ').repeater(console_width) + '|(cycle:' + cycle + ')\n'
            + '' + rig_line_build(dirt3 + rig.vt.join(''), f_idx) + '|(rate:' + processing_at + ')[AGRG:'+f_rate+']\n'
            + '' + rig_line_build(dirt2 + rig.vm.join(''), f_idx) + '|(index:' + f_idx + ')\n'//'|\n'
            + '' + rig_line_build(dirt + rig.vb.join(''), f_idx) + '|(exits @:' + (console_width + rig.vt.length + 10) + ' in:'+((console_width + rig.vt.length + 10)-f_idx)+'F )\n'
            + '' + '¨'.repeater(console_width*2 + rig.vt.length + 10).substring(f_idx, console_width + f_idx) + '`\n'// this is the road
            , 'font-family:monospace');

            $tamper = setTimeout(function(){
                //stamp( ( ( f_idx > (console_width + rig.vt.length + 10) ) ? 1 : f_idx ), ( ( f_idx > (console_width + rig.vt.length + 10) ) ? (Math.floor(Math.random() * 3) + 6) :  Math.floor((f_rate +1) * 1.025 )) );
            },258);
    }
    w.prime('state', w, { value: {}, override: false }); // honestly this shouldn't be this way, should be smarter
    w.prime('debug', w.state, { value: false, override: false, merge: false });
    w.prime('env', w.state, { value: 'development', override: false, merge: false });
    w.prime('console', w.state, { value: true, override: false, merge: false });
    var m = 'c:' + (w.state.console || !(w.state.console.length > 0) ? "true" : "false") +
        ':d:' + w.state.debug.toString() +
        ':e:' + w.state.env.toString().toLowerCase();
    if ((!w.state.debug && w.state.env.toString().toLowerCase() !== 'development')
        || !w.state.console) {
        w.console = console;
        console.log(m + '-- Turning console (general std_out) OFF');
        console.log = console.debug = console.info = console.warn = console.trace = console.error = function () { }; //console.exception =
    }
    stamp(frame_index,frame_rate);
    w._l( "YOUR SERVICE IS `" + ( static_stamp ? "STOPPED" : "Started" ) + "`:  Ctrl + Alt + Shift + E which stops the console protection if your user is cleared to do that.  If your seeing this, you appear to have that right.  Use key strokes again to toggele the service state." );

}(WSU));



/*
* jQuery helpers.  May move away from here, but must proxy anything
*/
(function ($, jQuery) { // this will depend on jQuery

    var $eeker: any = null;
    function jqueryExtend(i: number) {
        clearTimeout($eeker);
        $eeker = null;
        if (WSU.defined(jQuery, "function")) {
            jQuery.expr[":"].regex = function (elem: any, index: any, match: any) {
                var matchParams = match[3].split(","),
                    validLabels = /^(data|css):/,
                    attr = {
                        method: matchParams[0].match(validLabels) ?
                            matchParams[0].split(":")[0] : "attr",
                        property: matchParams.shift().replace(validLabels, "")
                    },
                    regexFlags = "ig",
                    regex = new RegExp(matchParams.join("").replace(/^\s+|\s+$/g, ""), regexFlags);
                return regex.test(jQuery(elem)[attr.method](attr.property));
            };
            $.fn.overflown = function () { let e = this[0]; return e.scrollHeight > e.clientHeight || e.scrollWidth > e.clientWidth; };
            //targeted.uniqueId(); | removeUniqueId() // jQuery ext
            (function (factory) {
                if (WSU.defined((<any>window).define, "function") && (<any>window).define.amd) {
                    // AMD. Register as an anonymous module.
                    (<any>window).define(["jquery", "./version"], factory);
                } else {
                    // Browser globals
                    factory(jQuery);
                }
            }(function ($: any) {
                return jQuery.fn.extend({
                    uniqueId: (function () {
                        var uuid = 0;
                        return function () {
                            return this.each(function () {
                                if (!this.id) {
                                    this.id = "fw-trc-" + (++uuid);
                                }
                            });
                        };
                    })(),
                    removeUniqueId: function () {
                        return this.each(function () {
                            if (/^fw-trc-\d+$/.test(this.id)) {
                                $(this).removeAttr("id");
                            }
                        });
                    }
                });
            }));
            $.render = WSU.render;
            return;
        }
        if (i === 10 || i === 20) {
            console.exception('After ' + i + ' tries and about a wait of ' + ((i * 200) / 1000) + ' secounds, jquery was not seen.  We have stopped looking.');
            if (i === 20) {
                return;
            }
        }
        $eeker = setTimeout(function () {
            jqueryExtend(i++);
        }, 200);
    }
    jqueryExtend(1);
}((<any>window).jQuery, (<any>window).jQuery));
